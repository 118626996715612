<template>
    <div class="gallery-module-content">
        <h1 v-if="config.images.length == 0">Double Click me ...</h1>
        <img 
            v-for="(image, i) in config.images" 
            :key="i" :class="'gallery-img-'+(i + 1)"
            @click="openGallery(i)"
            v-lazy="getImg(image)" alt="img"
            >
        <light-box ref="lightbox" :show-light-box="false" :media="items"></light-box>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";
    import LightBox from 'vue-image-lightbox';
    require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
    
    export default {
        props:["config"],
        components:{
            LightBox
        },
        data(){
            return {items:[{img: "temp"}]}
        },
        mounted(){
            if(!this.config.images){
                this.config["images"] = []
            }
        },
        methods:{
            openGallery(index) {
                this.items = []
                for(let i=0; i < this.config.images.length; i++){
                    let imgFetch = imgManager.getImage(this.config.images[i]);
                    let img;
                    if(imgFetch.path){
                        img = {
                            src: imgFetch.path,
                            thumb: imgFetch.path,
                            caption: "Some image test"
                        }
                    }else{
                        img = {
                            src: imgFetch.file,
                            thumb: imgFetch.file,
                            caption: "Some image test"
                        }
                    }
                    this.items.push(
                        img
                    )
                }
                this.$refs.lightbox.showImage(index);
            },
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            }
        }
    }
      
</script>